<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Category' : 'Create Category'" />
        <div>
          <md-button :to="{ name: 'Faq Category' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-field :class="getValidationClass('first_name')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('description')">
            <label>Description </label>
            <md-textarea v-model="form.description"></md-textarea>
          </md-field>

          <mdc-button :loading="loading">{{
            id ? "Update Category" : "Create Category"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("faq");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {},
    loading: false,
    fetching: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createCategory", "updateCategory", "getCategory"]),
    async validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id
            ? this.updateCategory(data)
            : this.createCategory(data);
          let msg = this.id ? "Category Updated" : "Category Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Faq Category" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getCategory(this.id).then((category) => {
        this.form = {
          id: category.id,
          title: category.title,
          description: category.description,
        };
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
